import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { withTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'
import { VolcanoIntermediarySignupWidget } from "@volcanoteide/vte-components"
import UIkit from 'uikit'

class IntermediarySignup extends Component {

	static contextType = I18nextContext;

	componentDidMount() {
		if (typeof window !== `undefined`) {
			UIkit.container = '.uk-scope';
		}
	}

	render() {

		const { t } = this.props;

		const successConfig = {
			title: t("intermediary_signup_success_title"),
			body: t("intermediary_signup_success_body")
		};
		
		const formConditions = [
			{
				id: "terms",
				title: t("intermediary_signup_condition_1"),
				required: true,
				type: "checkbox"
			},
			{
				id: "data_consent",
				title: t("intermediary_signup_condition_2"),
				required: true,
				type: "checkbox"
			}
		];

		const defaultValues = {
			commercial: {
				collaboration_type: {
					id: {
						value: "collaborator_partner",
						hidden: true
					}
				},
				agency_type: {
					id: {
						value: "tourist_intermediary"
					}
				},
				business_type: {
					id: {
						value: "establishment_active_tourism",
						hidden: true
					}
				},
				contact_details: {
					country_id: {
						value: "ES"
					}
				}
			},
			billing: {
				contact_details: {
					country_id: {
						value: "ES"
					}
				}
			}
		};
	
		return	<div className={`articulo pb-5`}>
					<StaticQuery
						query={graphql`
							query VolcanoTeideAPIConfig {
								site {
									siteMetadata {
										volcanoTeideAPIConfig {
											protocol
											host
											port
											strictSSL
											timeout
											site_key
										}
									}
								}
							}
						`}
						render={data => (
							<div className="intermediary-signup page">
								<VolcanoIntermediarySignupWidget 
									apiConfig={data.site.siteMetadata.volcanoTeideAPIConfig}
									locale={this.context.language}
									conditions={formConditions}
									successConfig={successConfig}
									defaultValues={defaultValues}
								/>
							</div>
						)}
					/>
				</div>;
	}
}

export default withTranslation('IntermediarySignup')(IntermediarySignup);