/**
 * Método que parsea los tipos de moneda que nos llegan del servidor al símbolo de dicha moneda.
 * Si nos llega una moneda desconocida, mostramos lo que llega
 *
 * @param {string} currency 
 */
let parseCurrency = function(currency) {
    let parsedCurrency = currency;
    if (currency === "EUR") {
        parsedCurrency = "€";
    }
    return parsedCurrency;
}
   
exports.parseCurrency = parseCurrency;

/**
 * Método para url canonical
 * @param {string} path
 */
const setPathCanonical = (path) => {
    if (path === undefined || path === null) {
        return null;
    }

    const init = path.indexOf('#');
    if (init !== -1) {
        return path.slice(0, init);
    }

    return path;
}

exports.setPathCanonical = setPathCanonical;
