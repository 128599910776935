import React from "react"
import Layout from "../components/Layout"
import Helmet from "react-helmet"
import IntermediarySignup from "../components/IntermediarySignup"
import ContentContext from "../context/ContentContext"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import favicon from "../../static/favicon.svg"
import {I18nextContext, useTranslation} from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";
import { setPathCanonical } from "../components/lib/utils";

export default ( { pageContext } ) => {
  const { t } = useTranslation('Index');
  const { title } = useSiteMetadata()
  const titleLocale = pageContext.translations.find(value => value.lang === pageContext.lang)["title"]
  const htmlTitle = titleLocale + " | " + t(title)
  const location = useLocation();
  const { language } = React.useContext(I18nextContext);

	return (
    <ContentContext.Provider value={{ translations: pageContext.translations }}>
      <Layout>
        <Helmet>
          <html lang={language}/>
          <title>{htmlTitle}</title>
          <link rel="icon" href={favicon} />
          <meta name="title" content={t(title)} />
          <meta name="og:title" content={t(title)} />
          <meta name="og:site_name" content={title} />
          <meta name="description" content={t(`description_intermediary`)} />
          <meta name="og:type" content="website" />
          <meta name="og:url" content={setPathCanonical(location.href)} />
          <meta name="og:description" content={t(`description_intermediary`)} />
          <link rel="canonical" href={setPathCanonical(location.href)} />
        </Helmet>
        <h1 className="titulo-pre">{titleLocale}</h1>
        <IntermediarySignup></IntermediarySignup>
      </Layout>
    </ContentContext.Provider>
  )
}
